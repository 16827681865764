export default function IconArrowDown(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.75 -0.75 12 12"
      id="Arrows-Button-Down--Streamline-Micro"
      height="12"
      width="12"
    >
      <desc>Arrows Button Down Streamline Icon: https://streamlinehq.com</desc>
      <path
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.525 2.8875 4.8783 7.455a0.525 0.525 0 0 0 0.7434 0L9.975 2.8875"
        strokeWidth="1.5"
      ></path>
    </svg>
  );
}
